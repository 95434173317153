import { render, staticRenderFns } from "./Step3Component.vue?vue&type=template&id=b0b01a20&scoped=true&"
import script from "./Step3Component.vue?vue&type=script&lang=js&"
export * from "./Step3Component.vue?vue&type=script&lang=js&"
import style0 from "./Step3Component.vue?vue&type=style&index=0&id=b0b01a20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0b01a20",
  null
  
)

export default component.exports