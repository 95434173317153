import {Product} from "@/models/Product";

export const defaultProducts: Product[] = [
    {
        id: 'EH',
        title: "Einmalhandschuhe",
        manufacturer: "docdorado",
        packagingUnit: "100 Stk.",
        image: '/static/images/Nitrilhandschuhe.webp',
        quantity: 0,
        value: 7.18,
        selectedVariant: { id: 'NM', material: 'Nitril', size: 'M' }, // Setze eine Standardvariante
        variantOptions: [
            { id: 'VS', material: 'Vinyl', size: 'S' },
            { id: 'VM', material: 'Vinyl', size: 'M' },
            { id: 'VL', material: 'Vinyl', size: 'L' },
            { id: 'VXL', material: 'Vinyl', size: 'XL' },
            { id: 'NS', material: 'Nitril', size: 'S' },
            { id: 'NM', material: 'Nitril', size: 'M' },
            { id: 'NL', material: 'Nitril', size: 'L' },
            { id: 'NXL', material: 'Nitril', size: 'XL' },
            { id: 'LS', material: 'Latex', size: 'S' },
            { id: 'LM', material: 'Latex', size: 'M' },
            { id: 'LL', material: 'Latex', size: 'L' },
            { id: 'LXL', material: 'Latex', size: 'XL' }
        ]
    },
    { id: "F", packagingUnit: "1 Stk.", title: "FFP 2 Maske",manufacturer: "Famex",image: '/static/images/FFP2.webp', quantity: 0, value: 1 },
    { id: "M50", packagingUnit: "50 Stk.",title: "Medizinischer Mundschutz",manufacturer: "Meditrade", image: '/static/images/Mundschutz.webp', quantity: 0, value: 7.18 },
    { id: "HD", packagingUnit: "500 ml",title: "Händedesinfektionsmittel",manufacturer: "docdorado", image: '/static/images/docsept.webp', quantity: 0, value: 8.21 },
    { id: "FD", packagingUnit: "500 ml",title: "Flächendesinfektionsmittel",manufacturer: "docdorado", image: '/static/images/disinfectX.webp', quantity: 0, value: 6.16 },
    { id: "ES", packagingUnit: "100 Stk.",title: "Schutzschürzen",manufacturer: "Meditrade", image: '/static/images/Schuerzen.webp', quantity: 0, value: 13.30 },
    { id: "B", packagingUnit: "25 Stk.",title: "Bettschutz",manufacturer: "docdorado", image: '/static/images/superdry.webp', quantity: 0, value: 10.77 },
    { id: "WB", packagingUnit: "1 Stk.",title: "Wiederverwendbarer Bettschutz",manufacturer: "Maimed", image: '/static/images/WVBettschutz.webp', quantity: 0, value: 0 },
    { id: "W", packagingUnit: "50 Stk.",title: "Einmal-Waschhandschue",manufacturer: "Meditrade", image: '/static/images/Waschhandschuhe.webp', quantity: 0, value: 7.18 },
    { id: "FI", packagingUnit: "1 Stk.",title: "Fingerlinge",manufacturer: "Ampri", image: '/static/images/Fingerlinge.webp', quantity: 0, value: 5.64 },
    { id: "FDT", packagingUnit: "60 Stk.",title: "Flächendesinfektionstücher",manufacturer: "Dr. Schumacher", image: '/static/images/Tuecher.webp', quantity: 0, value: 11.90 },


    { id: "Elina1", packagingUnit: "250ml",title: "Duschgel",manufacturer: "Elina", image: '/static/images/Duschgel.webp', quantity: 0, value: 0 },
    { id: "Elina2", packagingUnit: "250ml",title: "Shampoo",manufacturer: "Elina", image: '/static/images/Shampoo.webp', quantity: 0, value: 0 },
    { id: "Elina3", packagingUnit: "75ml",title: "Handcreme",manufacturer: "Elina", image: '/static/images/Handcreme.webp', quantity: 0, value: 0 },
    { id: "Elina4", packagingUnit: "75ml",title: "Fußcreme",manufacturer: "Elina", image: '/static/images/Fußcreme.webp', quantity: 0, value: 0 },
    { id: "Elina5", packagingUnit: "75ml",title: "Zahnpasta",manufacturer: "Elina", image: '/static/images/Zahnpasta.png', quantity: 0, value: 0 },
    { id: "GE", packagingUnit: "80 Stk.",title: "Feuchttücher",manufacturer: "Meditrade", image: '/static/images/Feuchttuecher.webp', quantity: 0, value: 0 },

]