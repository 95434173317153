<template>
  <v-snackbar v-model="showBanner" :timeout="0" :absolute="false" :bottom="true" class="cookie-snackbar" color="white">
    <v-card flat tile class="d-flex flex-row align-center pa-2">
      <v-row no-gutters class="align-center justify-space-between fill-height">
        <v-col>
          <div class="text-left">
            Diese Website verwendet Cookies, um eine bestmögliche Erfahrung bieten zu können.
            <a href="https://www.aginea.de/datenschutz" target="_blank">Mehr Informationen ...</a>
          </div>
        </v-col>
        <v-col class="text-right">
          <v-btn small color="secondary" text @click="declineCookies">Ablehnen</v-btn>
          <v-btn small color="primary" @click="acceptCookies">Akzeptieren</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-snackbar>
</template>


<script>
import companyData from "@/indivconfig/companyData.json";

export default {
  name: "CookieDialog",

  data() {
    return {
      showBanner: !this.cookiesAccepted(),
      showMore: false
    };
  },
  methods: {
    cookiesAccepted() {
      // Prüfen, ob die Zustimmung bereits erteilt wurde
      return localStorage.getItem('cookiesAccepted') === 'true';
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.showBanner = false;
      this.$gtm.enable(true);
    },
    declineCookies() {
      this.$cookies.keys().forEach(k => this.$cookies.remove(k));
      this.showBanner = false;
      this.$gtm.enable(false);
    },
  }
};
</script>

<style scoped>
.cookie-snackbar .v-card {
  width: 100%;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.95); /* Leicht durchsichtiges Weiß */
  color: #000; /* Schwarzer Text */
}

@media (max-width: 600px) {
  .cookie-snackbar .v-card {
    flex-direction: column;
  }
}
</style>