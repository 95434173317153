<template>
  <div class="mb-8 hidden-sm-and-down">








    <v-row class="d-flex justify-center align-center " v-if="step === '1'">
      <v-col cols="2" class="step-container" gtag="Produktauswahl">
        <img
            src="/static/icons/Choose-product.png"
            class="mr-2 primary--text"
            alt="Choose-product Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container" gtag="Dateneingabe">
        <img
            src="/static/icons/Data-entry.png"
            class="mr-2 primary--text"
            alt="Data-entry Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container" gtag="Unterschrift">
        <img
            src="/static/icons/Signature.png"
            class="mr-2 primary--text"
            alt="Signature Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container" gtag="Fertig">
        <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">1. Schritt: Produkte wählen</div>
    </v-row>


    <v-row class="d-flex justify-center align-center" v-if="step === '2'" >
      <v-col cols="2" class="step-container" gtag="Produktauswahl">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="dividerFinish primary--text"></v-col>
      <v-col cols="2" class="step-container" gtag="Dateneingabe">
         <img
            src="/static/icons/Data-entry.png"
            class="mr-2 primary--text"
            alt="Data-entry Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container" gtag="Unterschrift">
         <img
            src="/static/icons/Signature.png"
            class="mr-2 primary--text"
            alt="Signature Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container" gtag="Fertig">
         <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">2. Schritt: Dateneingabe</div>
    </v-row>


    <v-row class="d-flex justify-center align-center" v-if="step === '3'">
      <v-col cols="2" class="step-container" gtag="Produktauswahl">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="dividerFinish primary--text"></v-col>
      <v-col cols="2" class="step-container" gtag="Dateneingabe">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="dividerFinish"></v-col>
      <v-col cols="2" class="step-container" gtag="Unterschrift">
         <img
            src="/static/icons/Signature.png"
            class="mr-2 primary--text"
            alt="Signature Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container" gtag="Fertig">
         <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">3. Schritt: Unterschrift</div>
    </v-row>


    <v-row class="d-flex justify-center align-center" v-if="step === '4'">
      <v-col cols="2" class="step-container" gtag="Produktauswahl">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="dividerFinish primary--text"></v-col>
      <v-col cols="2" class="step-container" gtag="Dateneingabe">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="dividerFinish"></v-col>
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="dividerFinish"></v-col>
      <v-col cols="2" class="step-container">
        <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">4. Schritt: Fertig</div>
    </v-row>


  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PB-Stepper",

  props: {
    step: String
  },

})
</script>



<style scoped>
.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper-step {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;

}

.step-number-and-title {
  display: flex;
  align-items: center;
  background: var(--v-primary-base); /* Hintergrundfarbe des aktiven Schrittes */
  color: white; /* Textfarbe des aktiven Schrittes */
  padding: 5px 7px; /* Abstand um Nummer und Titel */
  border-radius: 20px; /* Abgerundete Ecken */
  margin: 0 10px;
}

.step-circle {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circlefinish {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 2px solid var(--v-primary-base);

}

.step-circleactive {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circleactive::before {
  content: '';
  position: absolute;
  top: -1px; /* Positioniert den 3/4-Kreis zentriert */
  left: -1px; /* Positioniert den 3/4-Kreis zentriert */
  width: 30px; /* Größe des 3/4-Kreises */
  height: 30px; /* Größe des 3/4-Kreises */
  border: 1px solid white; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-title {

  padding-right: 5px;/* Größe des Schritttitels */
  padding-left: 5px/* Größe des Schritttitels */
}

.dotted-line {
  flex: 1;
  height: 2px;
  margin: 0 20px; /* Erhöht den Abstand zu den Seiten */
  background-image: linear-gradient(to right, transparent, #999, transparent); /* Erstellt eine gepunktete Linie */
  background-size: 10px 2px; /* Größe der Punkte */
  background-repeat: repeat-x;
}

/* 3/4-Kreis hinzufügen */
.step-circle::before {
  content: '';
  position: absolute;
  top: -2px; /* Positioniert den 3/4-Kreis zentriert */
  left: -2px; /* Positioniert den 3/4-Kreis zentriert */
  width: 34px; /* Größe des 3/4-Kreises */
  height: 34px; /* Größe des 3/4-Kreises */
  border: 2px solid var(--v-primary-base); /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding-left: 0px;
  padding-right: 0px;
}

.divider {
  border-top: 3px solid #aaa;
  margin: 0 15px;
  width: 30px;
}

.dividerFinish {
  border-top: 3px solid var(--v-primary-base) !important;
  margin: 0 15px;
  width: 30px;
}

@media (max-width: 1264px) { /* 'sm' and 'md' devices */
  .divider {
    border-top: 2px solid #aaa;
    margin: 0 5px;
    width: 10px;
    padding-top: 0;
  }
  .dividerFinish {
    border-top: 2px solid var(--v-primary-base) !important;
    margin: 0 5px;
    width: 10px;
    padding-top: 0;
  }
}
</style>