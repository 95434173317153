<template>
  <div>
    <v-container>
      <p-b-stepper step="1"/>

      <div class="stepper-container hidden-md-and-up">
        <!-- Schritt 1 -->
        <div class="stepper-step active-step">
          <div class="step-number-and-title">
            <div class="step-circleactive">1</div>
            <div class="step-title">Box Auswahl</div>
          </div>
        </div>

        <!-- Linie zum Schritt 2 -->
        <div class="dotted-line"></div>

        <!-- Schritt 2 -->
        <div class="stepper-step">
          <div class="step-circle">2</div>
        </div>

        <!-- Linie zum Schritt 3 -->
        <div class="dotted-line"></div>

        <!-- Schritt 3 -->
        <div class="stepper-step">
          <div class="step-circle">3</div>
        </div>
      </div>

      <v-row class="mt-2">

        <v-col cols="12" md="12" lg="12">
          <div class="text-right mt-4 hidden-sm-and-down">
            <v-btn :disabled="totalValue < minOrderValue" color="primary" @click="goToNextStep()" x-large gtag="weiter">
              Weiter
            </v-btn>
          </div>
        </v-col>
        <!-- Sichtbar nur auf md und größeren Bildschirmen -->
        <v-col cols="12" md="12" lg="6" class="hidden-sm-and-down">
          <h1 class="text-h3">Produkte wählen</h1>
          <p class="text-subtitle-1 secondary--text">Welche Produkte möchten Sie erhalten?
            <v-btn @click="openBoxSelection()" class="ml-2" color="primary" gtag="boxen auswahl">boxen auswahl</v-btn>
          </p>
        </v-col>

        <v-col cols="12" class="hidden-sm-and-up text-center">
          <h2 class="text-h4" style="color: var(--v-primary-base); margin-bottom: 20px">Wählen Sie Ihre Pflegebox</h2>
          <v-row justify="center" align="center"> <!-- Zeile für die Buttons, zentriert -->
            <span
                :class="['pflegebox-button', selectedBox === 'individuell' ? 'primary' : 'secondary']"
                @click="openIndividuelleBox"
            >
      Individuelle Box
    </span>
            <span
                :class="['pflegebox-button', selectedBox === 'grundpflege' ? 'primary' : 'secondary']"
                @click="openGrundpflegeBox"
            >
      Grundpflegebox
    </span>
          </v-row>
        </v-col>


        <v-col cols="12" md="12" lg="6" ref="stickyContainer">
          <div class="d-flex align-center justify-start">
            <h2 class="mr-2">Warenkorb</h2>
            <v-btn icon @click="emptyProductCart" class="mr-2" gtag="Warenkorb geleert">
              <v-icon :class="selectedProducts.length > 0 ? 'primary--text' : ''">mdi-delete</v-icon>
            </v-btn>
            <span @click="emptyProductCart" :class="selectedProducts.length > 0 ? 'primary--text' : ''"
                  gtag="Warenkorb geleert">
              leeren
            </span>
          </div>
          <div style="position: relative;">
            <v-progress-linear
                ref="progressBar"
                :value="totalValue * 100 / maxProgress"
                :max="100"
                show-value
                striped
                color="primary"
                background-color="accent"
                height="20"
                class="rounded-xl rounded-be-0"
            ></v-progress-linear>
            <div
                class="min-order-line tertiary"
                :style="{ left: `calc(${minOrderLinePosition}% - 1px)` }"
            ></div>
            <div
                class="min-order-text text-body-1"
                :style="{ left: `calc(${minOrderLinePosition}% - 15px)` }"
            >
              min. {{ minOrderLinePosition.toFixed(0) }}%
            </div>
          </div>
        </v-col>


      </v-row>

      <div class="product-card">
        <v-row class="fill-height">

          <!-- Produkte für Mobilgeräte mit Bild links und Text sowie Buttons rechts -->
          <v-col cols="12" v-if="isMobile() && selectedBox === 'individuell'">
            <div v-for="(product, index) in productsListA" :key="'listA-' + index" :elevation="3">
              <v-card class="ma-4 pa-4 rounded-lg" :elevation="3">
                <v-row>

                  <!-- Bildspalte mit überlagertem Mengenkreis -->
                  <v-col cols="3" class="position-relative">
                    <v-img :src="product.image"
                           style="vertical-align: center !important; text-align: center !important; height: 100%"
                           alt="Produktbild" max-height="150" contain/>
                    <div class="quantity-circle" v-if="product.quantity > 0">{{ product.quantity }}</div>
                  </v-col>

                  <!-- Textspalte -->
                  <v-col cols="7">
                    <v-card-text>
                      <p class="font-weight-bold subtitle-2 mb-0">{{ product.title }}</p>
                      <p class="text-subtitle-2 secondary--text">{{ product.packagingUnit }} - {{
                          product.manufacturer
                        }}</p>
                    </v-card-text>
                  </v-col>

                  <!-- Buttonspalte -->
                  <v-col cols="2" >
                    <div class="button-container pr-4">
                      <v-btn :disabled="product.quantity < 1" icon
                             :color="product.quantity > 0 ? 'primary' : ''" @click="decreaseProduct(product)">
                        <v-icon medium>mdi-minus</v-icon>
                      </v-btn>

                      <v-btn
                          icon
                          :disabled="disablePlusBtn(product)"
                          :color="(progress + product.value <= maxProgress && !(product.id === 8 && product.quantity >= 3)) ? 'primary' : ''"
                          @click="increaseProduct(product)">
                        <v-icon medium>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col v-if="product.variantOptions && product.quantity > 0">
                    <v-card outlined>
                      <v-card-text>
                        <v-row class="mx-4">
                          <v-col v-if="getSelectedProdukt(product.id)">
                            <div>Größe</div>
                            <v-chip-group
                                :value="getSelectedProdukt(product.id).selectedVariant.size"
                                mandatory
                                active-class="primary"
                            >
                              <v-chip
                                  v-for="size in ['S', 'M', 'L', 'XL']"
                                  :key="`size${size}${index}`"
                                  :value="size"
                                  @click="localUpdateAllVariantsByID(product.id,size,undefined)"
                                  :gtag="`Produktvariante: ${getSelectedProdukt(product.id).selectedVariant.size} ${getSelectedProdukt(product.id).title}`"
                              >
                                {{ size }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>

                          <v-col v-if="getSelectedProdukt(product.id)">
                            <div>Material</div>
                            <v-chip-group
                                :value="getSelectedProdukt(product.id).selectedVariant.material"
                                mandatory
                                active-class="primary"
                                @change="localUpdateAllVariantsByID(product.id, undefined,$event)"
                            >
                              <v-chip
                                  v-for="material in ['Vinyl', 'Nitril', 'Latex']"
                                  :key="`size${material}${index}`"
                                  :value="material"
                                  :gtag="`Produktmaterial: ${getSelectedProdukt(product.id).selectedVariant.material} ${getSelectedProdukt(product.id).title}`"
                              >
                                {{ material }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>


                  </v-col>
                </v-row>
              </v-card>

              <!-- Chips für Variantenauswahl unterhalb jeder Produktkarte -->
              <v-col cols="12">
                <v-card flat>
                  <v-row>

                    <!-- Chips für die Größenauswahl -->
                    <v-col cols="6">
                      <v-chip-group v-model="product.selectedSize" row active-class="primary">
                        <v-chip v-for="size in product.sizes" :key="`size-${size}-${index}`" small>{{ size }}</v-chip>
                      </v-chip-group>
                    </v-col>

                    <!-- Chips für die Materialauswahl -->
                    <v-col cols="6">
                      <v-chip-group v-model="product.selectedMaterial" row active-class="primary">
                        <v-chip v-for="material in product.materials" :key="`material-${material}-${index}`" small>
                          {{ material }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>

                  </v-row>
                </v-card>
              </v-col>
            </div>

            <v-alert type="info" class="mt-5 pa-4 ma-4" dense text>
              Wählen Sie bis zu 2 kostenlose Geschenke aus!
            </v-alert>

            <div v-for="(product, index) in productsListB" :key="'listB-' + index" :elevation="3">
              <v-card class="ma-4 pa-4 rounded-lg" :elevation="3">
                <v-row>

                  <!-- Bildspalte mit überlagertem Mengenkreis -->
                  <v-col cols="3" class="position-relative">
                    <v-img :src="product.image"
                           style="vertical-align: center !important; text-align: center !important; height: 100%"
                           alt="Produktbild" max-height="150" contain/>
                    <div class="quantity-circle" v-if="product.quantity > 0">{{ product.quantity }}</div>
                  </v-col>

                  <!-- Textspalte -->
                  <v-col cols="7">
                    <v-card-text>
                      <p class="font-weight-bold subtitle-2 mb-0">{{ product.title }}</p>
                      <p class="text-subtitle-2 secondary--text">{{ product.packagingUnit }} - {{
                          product.manufacturer
                        }}</p>
                    </v-card-text>
                  </v-col>

                  <!-- Buttonspalte -->
                  <v-col cols="2" >
                    <div class="button-container pr-4">
                      <v-btn :disabled="product.quantity < 1" icon
                             :color="product.quantity > 0 ? 'primary' : ''" @click="decreaseProduct(product)">
                        <v-icon medium>mdi-minus</v-icon>
                      </v-btn>

                      <v-btn
                          icon
                          :disabled="disablePlusBtn(product)"
                          :color="(progress + product.value <= maxProgress && !(product.id === 8 && product.quantity >= 3)) ? 'primary' : ''"
                          @click="increaseProduct(product)">
                        <v-icon medium>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col v-if="product.variantOptions && product.quantity > 0">
                    <v-card outlined>
                      <v-card-text>
                        <v-row class="mx-4">
                          <v-col v-if="getSelectedProdukt(product.id)">
                            <div>Größe</div>
                            <v-chip-group
                                :value="getSelectedProdukt(product.id).selectedVariant.size"
                                mandatory
                                active-class="primary"
                            >
                              <v-chip
                                  v-for="size in ['S', 'M', 'L', 'XL']"
                                  :key="`size${size}${index}`"
                                  :value="size"
                                  @click="localUpdateAllVariantsByID(product.id,size,undefined)"
                                  :gtag="`Produktvariante: ${getSelectedProdukt(product.id).selectedVariant.size} ${getSelectedProdukt(product.id).title}`"
                              >
                                {{ size }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>

                          <v-col v-if="getSelectedProdukt(product.id)">
                            <div>Material</div>
                            <v-chip-group
                                :value="getSelectedProdukt(product.id).selectedVariant.material"
                                mandatory
                                active-class="primary"
                                @change="localUpdateAllVariantsByID(product.id, undefined,$event)"
                            >
                              <v-chip
                                  v-for="material in ['Vinyl', 'Nitril', 'Latex']"
                                  :key="`size${material}${index}`"
                                  :value="material"
                                  :gtag="`Produktmaterial: ${getSelectedProdukt(product.id).selectedVariant.material} ${getSelectedProdukt(product.id).title}`"
                              >
                                {{ material }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>


                  </v-col>
                </v-row>
              </v-card>

              <!-- Chips für Variantenauswahl unterhalb jeder Produktkarte -->
              <v-col cols="12">
                <v-card flat>
                  <v-row>

                    <!-- Chips für die Größenauswahl -->
                    <v-col cols="6">
                      <v-chip-group v-model="product.selectedSize" row active-class="primary">
                        <v-chip v-for="size in product.sizes" :key="`size-${size}-${index}`" small>{{ size }}</v-chip>
                      </v-chip-group>
                    </v-col>

                    <!-- Chips für die Materialauswahl -->
                    <v-col cols="6">
                      <v-chip-group v-model="product.selectedMaterial" row active-class="primary">
                        <v-chip v-for="material in product.materials" :key="`material-${material}-${index}`" small>
                          {{ material }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>

                  </v-row>
                </v-card>
              </v-col>
            </div>

          </v-col>


          <v-col cols="12" v-if="isMobile() && selectedBox === 'grundpflege'">
            <!-- Visuelle Darstellung der vordefinierten Produkte ähnlich wie bei der individuellen Auswahl -->
            <div v-for="(productId, index) in predefinedSelectedProducts" :key="index">
              <v-card class="ma-4 pa-4 rounded-lg" :elevation="3">
                <v-row>
                  <!-- Bildspalte mit überlagertem Mengenkreis -->
                  <v-col cols="3" class="position-relative">
                    <v-img :src="getProductImage(productId)"
                           style="vertical-align: center !important; text-align: center !important; height: 100%"
                           alt="Produktbild" max-height="150" contain/>
                    <!-- Mengenkreis entfernt, da keine Änderung der Menge möglich ist -->
                  </v-col>

                  <!-- Textspalte -->
                  <v-col cols="9">
                    <v-card-text>
                      <p class="font-weight-bold subtitle-2 mb-0">{{ getProductVariantsByProductId(productId).title }}</p>
                      <p class="text-subtitle-2 secondary--text">{{ getProductVariantsByProductId(productId).packagingUnit }} - {{
                          getProductVariantsByProductId(productId).manufacturer
                        }}</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>


          <v-col cols="12" md="12" lg="6" v-if="!isMobile()">
            <v-card class="ma-4 pa-4 rounded-lg" v-for="(product, index) in productsListA" :key="'listA-' + index" :elevation="3">
            <v-row align="center">
                <v-col cols="12" md="12" lg="3">
                  <v-img class="hidden-md-and-down" :src="product.image" alt="Produktbild" max-height="70" contain/>
                  <v-img class="hidden-lg-and-up" :src="product.image" alt="Produktbild" max-height="150" contain/>
                </v-col>
                <v-col cols="12" md="12" lg="5" class="flex-column justify-center">
                  <p class="text-h6 mb-0">{{ product.title }}
                    <span class="text-subtitle-2 secondary--text">{{ product.packagingUnit }}</span>
                  </p>
                  <p class="text-subtitle-2 secondary--text">{{ product.manufacturer }}</p>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-row class="button-group" align="center" no-gutters justify="end">
                    <v-btn :disabled="product.quantity < 1" elevation="0" class="btn-size btn-minus rounded-lg"
                           :color="product.quantity > 0 ? 'primary' : ''"
                           @click="decreaseProduct(product)"
                           :gtag="'Produkt entfernt: ' + product.title"

                    >
                      <v-icon medium>mdi-minus</v-icon>
                    </v-btn>
                    <v-chip class="counter-chip " small color="accent" text-color="black">{{ product.quantity }}
                    </v-chip>
                    <v-btn elevation="0"
                           class="btn-size btn-plus rounded-lg"
                           :disabled="disablePlusBtn(product)"
                           :color="(progress + product.value <= maxProgress && !(product.id === 8 && product.quantity >= 3)) ? 'primary' : ''"
                           @click="increaseProduct(product)"
                           :gtag="'Produkt hinzugefügt: ' + product.title"
                    >
                      <v-icon medium>mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <!-- Hervorgehobener Text für Liste B -->
            <v-alert type="info" class="mt-5 pa-4 ma-4" dense text>
              Wählen Sie bis zu 2 kostenlose Geschenke aus!
            </v-alert>

            <v-card class="ma-4 pa-4 rounded-lg" v-for="(product, index) in productsListB" :key="'listB-' + index" :elevation="3">
            <v-row align="center">
                <v-col cols="12" md="12" lg="3">
                  <v-img class="hidden-md-and-down" :src="product.image" alt="Produktbild" max-height="70" contain/>
                  <v-img class="hidden-lg-and-up" :src="product.image" alt="Produktbild" max-height="150" contain/>
                </v-col>
                <v-col cols="12" md="12" lg="5" class="flex-column justify-center">
                  <p class="text-h6 mb-0">{{ product.title }}
                    <span class="text-subtitle-2 secondary--text">{{ product.packagingUnit }}</span>
                  </p>
                  <p class="text-subtitle-2 secondary--text">{{ product.manufacturer }}</p>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-row class="button-group" align="center" no-gutters justify="end">
                    <v-btn :disabled="product.quantity < 1" elevation="0" class="btn-size btn-minus rounded-lg"
                           :color="product.quantity > 0 ? 'primary' : ''"
                           @click="decreaseProduct(product)"
                           :gtag="'Produkt entfernt: ' + product.title"

                    >
                      <v-icon medium>mdi-minus</v-icon>
                    </v-btn>
                    <v-chip class="counter-chip " small color="accent" text-color="black">{{ product.quantity }}
                    </v-chip>
                    <v-btn elevation="0"
                           class="btn-size btn-plus rounded-lg"
                           :disabled="disablePlusBtn(product)"
                           :color="(progress + product.value <= maxProgress && !(product.id === 8 && product.quantity >= 3)) ? 'primary' : ''"
                           @click="increaseProduct(product)"
                           :gtag="'Produkt hinzugefügt: ' + product.title"
                    >
                      <v-icon medium>mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

          </v-col>

          <!-- rechte seite -->
          <v-col cols="12" md="12" lg="6" class="bordered-col mt-4" v-if="!isMobile()">
            <div v-if="selectedProducts.length === 0" class="empty-cart-message">
              Fügen Sie Produkte aus unserem Katalog hinzu
            </div>
            <div v-else>
              <v-card class="mb-4 rounded-lg pa-4">
                <v-row align="center" no-gutters>
                  <v-col cols="3" class="d-flex justify-center align-center"
                         style="padding-top: 10px; padding-bottom: 10px;">
                    <v-icon color="primary" size="40">mdi-information-variant-circle-outline</v-icon>
                  </v-col>

                  <v-col cols="9" class="primary--text">
                    Fügen Sie weitere Produkte aus unserem Katalog hinzu - <strong>Es entstehen keine
                    Zusatzkosten. </strong>
                  </v-col>
                </v-row>
              </v-card>

              <v-card flat class="mb-4 pa-4" v-for="(p, index) in selectedProducts" color="accent" :key="index">
                <v-row align="center" justify="center">
                  <v-col cols="12" md="12" lg="3">
                    <v-img class="hidden-md-and-down" :src="p.image" alt="Produktbild" max-height="70" contain/>
                    <v-img class="hidden-lg-and-up" :src="p.image" alt="Produktbild" max-height="150" contain/>
                  </v-col>
                  <v-col cols="9">
                    <p class="text-h6 mb-0">{{ p.title }}</p>
                    <p class="text-subtitle-1 secondary--text">{{ p.manufacturer }}</p>

                    <div v-if="p.variantOptions">
                      <div>Größe</div>
                      <v-chip-group :value="p.selectedVariant.size" mandatory @change="updateSelectedVariant({
                      index: index,
                      size: $event,
                      material: undefined
                      })" active-class="primary">
                        <v-chip v-for="size in ['S', 'M', 'L', 'XL']" :key="`size${size}${index}`" :value="size"
                                :gtag="`Produktvariante: ${p.selectedVariant.size} ${p.title}`">
                          {{ size }}
                        </v-chip>
                      </v-chip-group>

                      <div>Material</div>
                      <v-chip-group mandatory :value="p.selectedVariant.material" @change="updateSelectedVariant({
                      index: index,
                      size: undefined,
                      material: $event
                      })" active-class="primary">
                        <v-chip
                            v-for="material in ['Vinyl', 'Nitril', 'Latex']"
                            :key="`size${material}${index}`"
                            :value="material"
                            :gtag="`Produktmaterial: ${p.selectedVariant.material} ${p.title}`"
                        >
                          {{ material }}
                        </v-chip>
                      </v-chip-group>
                    </div>

                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-container>
      <v-row class="text-right">
        <v-col cols="12" lg="2">
          <v-checkbox v-model="isConsultant" label="Sind Sie ein Berater ?"></v-checkbox>
        </v-col>
        <v-col class="text-right" v-if="isConsultant" cols="12" lg="3">
          <v-text-field v-model="consultantNumber" outlined label="Beraternummer"></v-text-field>
        </v-col>
        <v-col class="text-right mt-4">
          <v-btn :disabled="totalValue < minOrderValue" color="primary" @click="goToNextStep()" x-large gtag="weiter">
            Weiter
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <p-b-snackbar :msg="snackbarMessage"/>

    <v-dialog v-model="dialog" max-width="725" persistent>
      <v-card rounded class="pa-4">
        <v-card-text>
          <v-btn
              @click="dialog = false"
              absolute
              right
              dark
              icon
          >
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>

          <v-item-group>
            <v-container>

              <div class="display-1  mb-2">Wählen Sie eine fertige Box aus!
              </div>
              <v-row>


                <v-col v-for="box in preDesignedBoxes" :key="box.title" cols="12" md="6">
                  <v-hover v-slot="{ hover }">
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                          :color="active ? 'primary' : 'white'"
                          @click="toggle(); selectBoxMethod(box)"
                          :gtag="'Box: ' + box.id"
                      >
                        <template v-if="!hover">
                          <v-img :src="box.image"/>
                        </template>


                        <v-card-text v-if="hover" class="text-center justify-center">
                          <v-virtual-scroll :items="box.productIds" item-height="50" height="255">
                            <template v-slot:default="{ item }">
                              <v-list-item>
                                <v-list-item-avatar>
                                  <v-avatar
                                      :color="item.color"
                                      size="56"
                                      class="white--text"
                                  >
                                    <v-img v-if="getProductVariantsByProductId(item)"
                                           :src="getProductVariantsByProductId(item).image"
                                           width="50"
                                    />
                                  </v-avatar>
                                </v-list-item-avatar>

                                <v-list-item-content v-if="getProductVariantsByProductId(item)">
                                  <v-list-item-title>
                                    <span
                                        style="color: black">{{ getProductVariantsByProductId(item).title }}</span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-virtual-scroll>
                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bettschutzDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline" style="background-color: var(--v-primary-base); color: white; word-break: break-word;">
          Möchten Sie zusätzlich Bettschutzeinlagen für 0€ erhalten?

          <v-btn icon @click="bettschutzDialog = false" color="red" style="position: absolute; right: 0px; top: 0px;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>


        <v-card-text class="text-center">
          <v-img src="static/images/WVBettschutz.webp" aspect-ratio="1.5" contain></v-img>
          <p>Erhalten Sie wiederverwendbare Bettschutzeinlagen, um Ihren Komfort zu erhöhen und gleichzeitig die Umwelt zu schonen.</p>
        </v-card-text>
        <v-card-actions class="flex-column justify-center pb-4">
          <div style="width: 100%;">
            <v-btn x-large block color="primary" @click="addBettschutz()">Kostenlosen Bettschutz</v-btn>
          </div>
          <div style="width: 100%; margin-top: 20px">
            <v-btn x-large block color="secondary" @click="continueWithout()">Weiter ohne Bettschutz</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>




  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import PBStepper from "@/components/PB-Stepper.vue";
import PBSnackbar from "@/components/PBSnackbar.vue";

export default {
  components: {PBSnackbar, PBStepper},
  data: () => ({
    snackbarMessage: {},
    dialog: false,
    active: false,
    isConsultant: false,
    isSticky: false,
    oldIndividuellBox: [],
    showDialogOnMediumAndUp: false,
    bettschutzDialog: false,
    selectedBox: '', // 'individuell' oder 'grundpflege'
    predefinedSelectedProducts: ['HD', 'FD', 'EH', 'B'], // IDs der vordefinierten Produkte
    productListTypeAIds: ['HD', 'FD', 'FDT', 'EH', 'B', 'W', 'FI', 'WB', 'F', 'ES', 'M50'], // IDs der Produkte, die in Liste A gehören
  }),
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.showDialogOnMediumAndUp = !this.isMobile(); // Setzen Sie den Wert basierend auf der Bildschirmgröße
    this.selectedBox = 'individuell'; // Oder 'grundpflege', je nach Bedarf
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters([
      'totalValue',
    ]),

    ...mapFields([
      'additionalInformation.consultantNumber',
      'system.products',
      'system.preDesignedBoxes',
      'system.selectedProducts',
      'system.progress',
      'system.maxProgress',
      'system.minOrderValue',
    ]),

    minOrderLinePosition() {
      return (this.minOrderValue / this.maxProgress) * 100
    },

    productsListA() {
      // Produkte, die in Liste A gehören
      return this.products.filter(product => this.productListTypeAIds.includes(product.id));
    },
    productsListB() {
      // Produkte, die nicht in Liste A gehören, kommen in Liste B
      return this.products.filter(product => !this.productListTypeAIds.includes(product.id));
    },

    isWBIncreased() {
      // Überprüfen Sie, ob das Produkt mit der ID 'WB' bereits hinzugefügt wurde
      // Dies hängt von der Struktur Ihrer Daten ab
      const product = this.selectedProducts.find(p => p.id === 'WB');
      return product && product.quantity > 0;
    }
  },
  methods: {
    //State Things
    ...mapActions([
      'updateProductVariant',
    ]),

    ...mapMutations(["increase", "decrease", "selectBox", "updateSelectedVariant", "updateAllVariantsByID", "emptyCart"]),

    openIndividuelleBox() {
      this.selectedBox = 'individuell';
      // Fügen Sie hier Logik hinzu, um den Warenkorb für die individuelle Box zu initialisieren
    },


    openGrundpflegeBox() {
      this.selectedBox = 'grundpflege';
      this.loadPredefinedProducts();
    },

    getProductImage(productId) {
      const product = this.products.find(p => p.id === productId);
      return product ? product.image : 'defaultImagePath'; // Gehen Sie davon aus, dass jedes Produkt eine 'image' Eigenschaft hat
    },


    loadPredefinedProducts() {
      this.emptyCart()
      this.predefinedSelectedProducts.forEach(productId => {
        this.increase(productId)
      });
    },

    localUpdateAllVariantsByID(produkt, size, material) {
      this.updateAllVariantsByID({
        id: produkt,
        size: size,
        material: material
      })
    },

    getProductVariantsByProductId(id) {
      return this.products.find(p => p.id === id)
    },


    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },

    handleScroll() {
      const stickyContainer = this.$refs.stickyContainer;
      if (stickyContainer) {
        const rect = stickyContainer.getBoundingClientRect();
        if (rect.top <= 0) {
          this.$store.state.system.isSticky = true;
        } else {
          this.$store.state.system.isSticky = false;
        }
      }
    },

    openBoxSelection() {
      this.dialog = true
    },

    disablePlusBtn(product) {
      // Gruppe der gefilternen GiveAways
      const ifStatement = (p) => p.id === 'Elina5' || p.id === 'Elina1' || p.id === 'Elina2' || p.id === 'Elina3' || p.id === 'Elina4' || p.id === 'GE'

      const onlyOneItem = this.selectedProducts
          .filter(p => ifStatement(p))
          .reduce((sum, currentValue) => sum + currentValue.quantity, 0); // Summiere alle ifStatement auf

      if (ifStatement(product)) return onlyOneItem >= 2 // 1 give away erlaubt

      if (product.id === 'WB') return product.quantity >= 3
      return false
    },

    selectBoxMethod(box) {
      this.dialog = false
      this.selectBox(box.id);
      this.showSnackbar('success', `Die ${box.title} wurde hinzugefügt.`);
    },

    getSelectedProdukt(produktID) {
      return this.selectedProducts.find(sp => sp.id === produktID)
    },

    goToNextStep() {
      if (this.isWBIncreased) {
        // Wenn WB bereits erhöht wurde, fahren Sie direkt fort
        this.continueToNextPage();
      } else {
        // Andernfalls öffnen Sie den Dialog, um den Benutzer zu fragen
        this.bettschutzDialog = true;
      }
    },

    addBettschutz() {
      this.increase('WB');
      this.bettschutzDialog = false;
      this.continueToNextPage();
    },
    continueWithout() {
      this.bettschutzDialog = false;
      this.continueToNextPage();
    },
    continueToNextPage() {
      this.$store.state.system.isSticky = false;
      this.$router.push({path: '/dateneingabe'});
      this.$vuetify.goTo(0).then()
    },

    increaseProduct(product) {
      if (this.progress + product.value <= this.maxProgress) {
        this.increase(product.id);


        if (this.isMobile()) {
          const allSameProducts = this.selectedProducts
              .filter(sp => sp.id === product.id);

          allSameProducts
              .forEach(sp => {
                const productVariant = allSameProducts[0].selectedVariant;
                if(productVariant) sp.selectedVariant.size = productVariant.size
                if(productVariant) sp.selectedVariant.material = productVariant.material
              })
        }

        if (this.selectedProducts.length === 1) {

          this.showSnackbar('success', `${product.title} wurden hinzugefügt. Wir haben für Sie zusätzlich den Wiederverwendbaren Bettschutz mit hinzugefügt. Die Kosten werden von Ihrer Pflegekasse übernommen. 🎉`);
        } else {
          this.showSnackbar('success', `${product.title} wurden hinzugefügt.`);
        }
      } else {
        this.showSnackbar('error', `Produkt kann nicht hinzugefügt werden. Maximaler Fortschritt würde überschritten.`);
      }
    },

    decreaseProduct(product) {
      this.decrease(product.id);
      this.showSnackbar('success', `${product.title} wurde entfernt.`);
    },

    emptyProductCart() {
      this.emptyCart();
      this.showSnackbar('error', `Warenkorb wurde geleert.`);
    },

    showSnackbar(color, text) {
      if (!this.isMobile()) { // Überprüfen Sie, ob das Gerät md and up ist
        this.snackbarMessage = {text: text, type: color};
      }
    },
  }
};
</script>


<style scoped>
.empty-cart-message {
  border: 2px dashed #d3d3d3;
  border-radius: 10px;
  color: #d3d3d3;
  text-align: center;
  padding: 2em;
  height: 100%;
}

.button-container {
  display: flex;
  justify-content: center; /* Zentriert den Inhalt horizontal */
  align-items: center; /* Zentriert den Inhalt vertikal */
  height: 100%; /* Stellen Sie sicher, dass der Container die volle Höhe seiner Eltern einnimmt */
}

.button-container .v-btn {
  margin-right: 4px; /* Adjust the space between buttons */
}

.pflegebox-button {
  display: inline-block; /* Stellt sicher, dass span wie ein Block behandelt wird */
  padding: 10px 10px; /* Innenabstand für größere Berührungsfläche */
  color: white; /* Weißer Text */
  font-weight: bold; /* Fetter Text */
  margin: 5px 10px; /* Etwas Abstand zwischen den Elementen und zur Überschrift */
  cursor: pointer; /* Cursor ändern, um Klickbarkeit anzuzeigen */
  border-radius: 20px; /* Abgerundete Ecken für das Button-ähnliche Aussehen */
}

.pflegebox-button.primary {
  background-color: #007BFF; /* Blaue Hintergrundfarbe für den primären Button */
}

.pflegebox-button.secondary {
  background-color: #6c757d; /* Graue Hintergrundfarbe für den sekundären Button */
}

span.v-chip.v-chip--active. {
  background-color: #007BFF !important;
}


.btn-size {
  min-width: 40px !important;
  min-height: 40px !important;
  width: 40px !important;
  height: 40px !important;
}

.btn-minus {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-plus {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}


.counter-chip {
  min-height: 41px;
  height: 41px;
  min-width: 40px !important;
  width: 40px !important;
  justify-content: center;
  color: black;
  border-radius: 0;
}

.button-group {
  margin-right: 20px;
}

.quantity-circle {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  width: 30px;
  height: 30px;
  background-color: #FF5252; /* Your color choice */
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}


.min-order-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  transition: left 0.2s ease-in-out;
  z-index: 1;
  color: var(--v-tertiary-base)
}

.min-order-text {
  position: absolute;
  bottom: 100%;
  transform: translateY(-5px);
  transition: left 0.2s ease-in-out;
  z-index: 1;
  color: var(--v-tertiary-base) !important;
}

.product-card .col {
  padding: 0 !important;
  padding-right: 0px !important;
}

.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper-step {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;

}

.step-number-and-title {
  display: flex;
  align-items: center;
  background: #3eab36; /* Hintergrundfarbe des aktiven Schrittes */
  color: white; /* Textfarbe des aktiven Schrittes */
  padding: 5px 7px; /* Abstand um Nummer und Titel */
  border-radius: 20px; /* Abgerundete Ecken */
  margin: 0 10px;
}

.step-circle {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circlefinish {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 2px solid var(--v-primary-base);

}

.step-circleactive {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circleactive::before {
  content: '';
  position: absolute;
  top: -1px; /* Positioniert den 3/4-Kreis zentriert */
  left: -1px; /* Positioniert den 3/4-Kreis zentriert */
  width: 30px; /* Größe des 3/4-Kreises */
  height: 30px; /* Größe des 3/4-Kreises */
  border: 1px solid white; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-title {

  padding-right: 5px;/* Größe des Schritttitels */
  padding-left: 5px/* Größe des Schritttitels */
}

.dotted-line {
  flex: 1;
  height: 2px;
  margin: 0 20px; /* Erhöht den Abstand zu den Seiten */
  background-image: linear-gradient(to right, transparent, #999, transparent); /* Erstellt eine gepunktete Linie */
  background-size: 10px 2px; /* Größe der Punkte */
  background-repeat: repeat-x;
}

/* 3/4-Kreis hinzufügen */
.step-circle::before {
  content: '';
  position: absolute;
  top: -2px; /* Positioniert den 3/4-Kreis zentriert */
  left: -2px; /* Positioniert den 3/4-Kreis zentriert */
  width: 34px; /* Größe des 3/4-Kreises */
  height: 34px; /* Größe des 3/4-Kreises */
  border: 2px solid #3eab36; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 991px) {


  .sticky-container {
    position: -webkit-sticky; /* Für Safari */
    position: sticky !important;
    top: 50px; /* Abstand von oben, wenn es sticky wird */
    z-index: 100; /* Stellen Sie sicher, dass es über anderen Inhalten liegt */
  }


  /* Weitere spezifische Anpassungen... */
}


</style>
