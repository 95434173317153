import Vue from 'vue'
import App from './App.vue'
import store from './system/store'
import router from "./system/router";
import vuetify from './system/vuetify'
import FlagIcon from "vue-flag-icon";
import VueSignaturePad from "vue-signature-pad";
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(FlagIcon);
Vue.use(VueSignaturePad);


router.beforeEach((to, from, next) => {
  if (to.path === '/boxauswahl' || to.path === '/' || to.path.includes("/unterschrift/")) {
    next()
  } else {
    if (store.state.system.selectedProducts.length === 0) {
      // Wenn der Store leer ist, leite zur Startseite um
      next('/boxauswahl')
    } else {
      // Wenn der Store nicht leer ist, lasse die Navigation zu
      next()
    }
  }
})

Vue.config.productionTip = false

Vue.use(VueGtm, {
  id: 'GTM-ML4DWF7W',
  enabled: true,
  debug: false,
  vueRouter: router,
});

Vue.use(require('vue-cookies'))

new Vue({
  vuetify,
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('initialiseStore').then();
  },
  render: h => h(App),

  mounted() {
    document.addEventListener('click', (event: any) => {
          let aktuellesElement = event.target;
          let versuche = 0;

          while (versuche < 15) {
              // Prüfen, ob aktuellesElement existiert und das 'getAttribute'-Methode hat
              if (aktuellesElement && aktuellesElement.getAttribute) {
                  const gtagValue = aktuellesElement.getAttribute('gtag');
                  if (gtagValue) {
                      this.$gtm.trackEvent({
                          event: `${this.$route.path} - ${gtagValue}`,
                          category: gtagValue,
                          action: 'click',
                      });
                      return;
                  }
              } else {
                  return;
              }
              aktuellesElement = aktuellesElement.parentElement;
              versuche++;
          }
          versuche = 0;
      }
    );
  },
}).$mount('#app')
