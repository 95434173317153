<template>
  <div>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            :label="label"
            :rules="rules"
            outlined
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          locale="de"
          v-model="date"
          :active-picker.sync="activePicker"
          :min="min"
          :max="max"
          @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    defaultDate: String,
    // eslint-disable-next-line vue/require-prop-type-constructor
    label: String,
    min: String,
    max: String,
    rules: Object | []
  },
  mounted() {
    this.date = this.defaultDate
  },
  data: () => ({
    activePicker: '',
    date: null,
    menu: false,
  }),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    defaultDate(val){
      this.date = val
    }
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
      this.$emit("change", date)
    },
  },
}
</script>

<style scoped>

</style>