<template>
  <v-container>
    <v-card>
      <v-card-text>
        <h2 class="text-h5">Zusammenfassung Ihrer Bestellung</h2>
      </v-card-text>
      <v-card-text>
        <v-row class="mb-4 text-body-2">
          <v-col v-if="patientAddress" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <h2 class="mb-4 text-h6">
              Patientenanschrift
              {{`${deliveryAddress && deliveryAddress.firstName ? '' : '& Lieferanschrift'} `}}
            </h2>
            <div>
              <span class="font-weight-medium">Name: </span>
              {{ patientAddress.title }}
              {{ patientAddress.firstName }}
              {{ patientAddress.lastName }}
            </div>
            <div>
              <span class="font-weight-medium">Anschrift: </span>
              {{ patientAddress.street }},
              {{ patientAddress.zipCode }}
              {{ patientAddress.city }}
            </div>
            <div v-if="patientAddress.email">
              <span class="font-weight-medium">Email: </span>
              {{ patientAddress.email }}
            </div>
            <div v-if="patientAddress.phoneNumber">
              <span class="font-weight-medium">Telefon: </span>
              {{ patientAddress.phoneNumber }}
            </div>
          </v-col>
          <v-col v-if="deliveryAddress && deliveryAddress.firstName" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <h2 class="mb-4 text-h6">Lieferanschrift</h2>
            <div>
              <span class="font-weight-medium">Name: </span>
              {{ deliveryAddress.title }}
              {{ deliveryAddress.firstName }}
              {{ deliveryAddress.lastName }}
            </div>
            <div>
              <span class="font-weight-medium">Anschrift: </span>
              {{ deliveryAddress.street }},
              {{ deliveryAddress.zipCode }}
              {{ deliveryAddress.city }}
            </div>
            <div v-if="deliveryAddress.email">
              <span class="font-weight-medium">Email: </span>
              {{ deliveryAddress.email }}
            </div>
            <div v-if="deliveryAddress.phoneNumber">
              <span class="font-weight-medium">Telefon: </span>
              {{ deliveryAddress.phoneNumber }}
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center mb-2">
              <h2 class="text-h6">Ihre Konfiguration</h2>
              <v-img src="\static\images\paketlieferlogistik.png" max-width="50" class="d-block"/>
            </div>
          </v-col>
          <v-col class="py-0" cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                 v-for="(product, index) in selectedProducts"
                 :key="index">
            <v-icon>mdi-label-outline</v-icon>
            <span class="ml-4">
              <span class="font-weight-medium">{{ product.packagingUnit }} - {{ product.title }}</span>
              {{ product.selectedVariant?.size }}
              {{ product.selectedVariant?.material }}
          </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
  name: "PB-Summary",

  computed: {
    ...mapFields([
      'customer.patientAddress',
      'customer.deliveryAddress',
      'system.selectedProducts',
    ]),
  },
}
</script>
