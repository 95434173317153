<template>
  <v-container>
    <p-b-stepper step="4"/>
    <div class="step4 pt-4">
      <h1 class="text-xl sm:text-h1 text-secondary font-medium mb-4 mb-4"> Vielen Dank für Ihre Bestellung! </h1>
      <v-row>
        <v-col md="6">
          <div class="text-gray mb-8 text-xl"> Vielen Dank für Ihre Bestellung. Wir werden Ihre erste Lieferung ausführen, sobald wir die Kostenübernahme von Ihrer Pflegekasse bestätigt bekommen haben. Danach setzen wir mit Ihrem ausgewählten Lieferintervall fort. </div>
        </v-col>
      </v-row>
      <v-btn @click="goToLink(homeSiteLink)"
             color="primary"
             block
             outlined>
        Zurück zur Startseite
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import homesiteLink from "@/indivconfig/headerInfo.json";

import PBStepper from "@/components/PB-Stepper.vue";

export default {
  components: {PBStepper},
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },
  data() {
    return {
      homeSiteLink: homesiteLink.url,
    };
  },
};
</script>

<style scoped>

</style>
