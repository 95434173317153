<template>
  <v-container>
    <p-b-stepper step="3"/>

    <div class="stepper-container hidden-md-and-up">
      <!-- Schritt 1 -->
      <div class="stepper-step">
        <div class="step-circlefinish"> <v-icon color="black">mdi-check</v-icon> </div>
      </div>

      <!-- Linie zum Schritt 2 -->
      <div class="dotted-line"></div>

      <!-- Schritt 2 -->
      <div class="stepper-step">
        <div class="step-circlefinish"> <v-icon color="black">mdi-check</v-icon> </div>
      </div>

      <!-- Linie zum Schritt 3 -->
      <div class="dotted-line"></div>

      <!-- Schritt 3 -->
      <div class="stepper-step active-step">
        <div class="step-number-and-title">
          <div class="step-circleactive">3</div>
          <div class="step-title">Unterschrift</div>
        </div>
      </div>
    </div>
    <v-row justify="space-between" class="hidden-sm-and-down">
      <v-col cols="auto mt-4">
        <v-btn :disabled="isLoading" @click="goToLastStep()" text outlined x-large gtag="zurück">Zurück</v-btn>
      </v-col>
      <v-col cols="auto">
        <div class="text-right mt-4">
          <v-btn :disabled="isLoading" :loading="isLoading" color="primary" @click="goToNextStep()" x-large gtag="Bestellung abschließen">Bestellung abschließen
          </v-btn>
        </div>
      </v-col>
    </v-row>


    <h1 class="text-h4 mt-4">Daten des Antragstellers</h1>

    <v-form ref="form" v-model="isFormValid" lazy-validation class="mt-4">
      <v-row>
        <v-col cols="12" class="mt-4">
          <v-card rounded>
            <v-card-text>
              <v-card-text>
                <v-radio-group v-model="requestFor">
                  <v-row class="d-flex align-center mb-2">
                    <v-radio label="Ich beantrage für mich selbst" value="self" class="pl-5 text--black"></v-radio>
                  </v-row>
                  <v-row class="d-flex align-center">
                    <v-radio label="Ich beantrage für einen Angehörigen" value="other"
                             class="pl-5 text--black"></v-radio>
                  </v-row>
                </v-radio-group>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="requestFor === 'other'">
          <v-card rounded>
            <v-card-text>
              <h2 class="text-h5">Kontaktdaten für eventuelle Rückfragen</h2>
              <v-card-text>

                <v-row>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field v-model="customer.contactAddress.firstName" :rules="nameRules" label="Vorname"
                                  outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field v-model="customer.contactAddress.lastName" :rules="nameRules" label="Nachname"
                                  outlined></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field v-model="customer.contactAddress.phoneNumber" :rules="phoneRules"
                                  label="Telefonnummer"
                                  outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field v-model="customer.contactAddress.email" :rules="emailRules" label="E-Mailadresse"
                                  outlined></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field v-model="customer.contactAddress.street" :rules="streetRules" label="Straße & Hausnr."
                                  outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="2" xl="2">
                    <v-text-field v-model="customer.contactAddress.zipCode" :rules="zipRules" label="PLZ"
                                  outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="6" lg="4" xl="4">
                    <v-text-field v-model="customer.contactAddress.city" :rules="cityRules" label="Stadt"
                                  outlined></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>

        <p-b-summary/>

        <v-col cols="12">
          <p-b-signature/>
        </v-col>

        <v-col cols="12">
          <v-card rounded>
            <v-card-text class="pb-8">
              <v-checkbox :rules="notempty" v-model="isMoveToUs"
                          label="Sollte bereits ein Vertrag zur Lieferung bestehen, so beauftrage ich Aginea mit der Kündigung des Vertrages meines bisherigen Anbieters mit dem heutigen Datum."/>

              <v-checkbox
                  :rules="dataProtection"
                  v-model="agreeToPrivacyPolicy"
                  value="success"
                  hide-details
                  class="mr-2">
                <template v-slot:label>
                 <span>
                  Ich habe die
                  <a @click.stop href="https://www.aginea.de/datenschutz" target="_blank" rel="noopener"
                     class="privacy-link primary--text">Datenschutzerklärung</a>
                  gelesen und bin mit dieser einverstanden.
                </span>
                </template>
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="space-between">
      <v-col cols="auto mt-4">
        <v-btn
               :disabled="isLoading" @click="goToLastStep()" text outlined x-large gtag="zurück">Zurück</v-btn>
      </v-col>
      <v-col cols="auto">
        <div class="text-right mt-4">
          <p-b-share/>
          <v-btn :disabled="isLoading" :loading="isLoading" color="primary" @click="goToNextStep()" x-large gtag="Bestellung abschließen">Bestellung abschließen
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" v-if="!isOnline" class="justify-end text-end align-end">
        <v-icon color="error">mdi-wifi-remove</v-icon>
        <span class="ml-2 error--text">Kein Internet</span>
      </v-col>
    </v-row>

    <p-b-snackbar :msg="snackbarMessage"/>
  </v-container>
</template>


<script>
import moment from "moment/moment";
import {useOnline} from '@vueuse/core'
import {mapFields} from "vuex-map-fields";
import PBStepper from "@/components/PB-Stepper.vue";
import PBSnackbar from "@/components/PBSnackbar.vue";
import PBSummary from "@/components/PB-Summary.vue";
import PBShare from "@/components/PB-Share.vue";
import PBSignature from "@/components/PB-Signature.vue";
import {mapMutations} from "vuex";


export default {
  components: {PBSignature, PBShare, PBSummary, PBSnackbar, PBStepper},
  data() {
    return {
      isFormValid: false,
      now: moment().format("YYYY-MM-DD"),

      nameRules: [v => !!v || 'Bitte geben Sie Ihren Namen an.'],
      phoneRules: [v => !!v || 'Bitte geben Sie Ihre Telefonnummer an.'],
      emailRules: [
        v => !!v || 'E-Mail ist erforderlich',
        v => /.+@.+\..+/.test(v) || 'E-Mail muss gültig sein',
        // Hier können Sie die E-Mail-Domains hinzufügen, die Sie blockieren möchten
        v => !/(fake\.com|spam\.de|example\.org)$/.test(v) || 'Diese E-Mail-Domain ist nicht erlaubt'
      ],
      streetRules: [v => !!v || 'Bitte geben Sie Ihre Straße an.'],
      zipRules: [v => !!v || 'Bitte geben Sie Ihre Postleitzahl an.'],
      cityRules: [v => !!v || 'Bitte geben Sie Ihre Stadt an.'],
      dateRules: [v => !!v || 'Bitte geben Sie das Datum an.'],
      dataProtection: [v => !!v || 'Bitte bestätigen Sie die Datenschutzerklärung.'],
      notempty: [v => !!v || 'Bitte füllen Sie alle Pflichtfelder aus.'],
      moveToUsRules: [v => !!v || 'Bitte geben Sie ein Datum an.'],

      requestFor: 'self',

      agreeToPrivacyPolicy: false,

      snackbarMessage: {},
      isMoveToUs: false,
      moveToUsDate: "",
      isDatePickerOpen: false,

      // sending
      isLoading: false,
    };
  },

  computed: {
    ...mapFields([
      'customer',
      'additionalInformation',
      'customer.signature',
      'system.selectedProducts',
      'system.selectedBox',
      'system.requestChain'
    ]),

    isOnline() {
      return useOnline()?.value
    }
  },

  methods: {
    ...mapMutations(['resetStore']),

    async goToLastStep() {
      this.$router.push('/dateneingabe').then();
      this.$vuetify.goTo(0).then()
    },

    sendPostRequest(output) {
      const url = 'https://www.docdorado.de/register';
      try {
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(output),
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(response => response.text())
            .then((rawBody) => {
              if (rawBody.includes("CareBox already exists.")) {
                this.$router.push({path: '/fehler?error=bereitsAngelegt'}).then()
              } else {
                this.$router.push({path: '/vielendank'}).then()
                this.resetStore()
              }

            }).catch(e => {
          console.log(e)
        }).finally(() => {
          this.isLoading = false;
        })

      } catch (e) {
        console.log("Error: ", e);
        this.isLoading = false;
      }
    },

    checkOnlineAndSend(output) {
      this.isLoading = true;
      if (this.isOnline) {
        console.log("sending...")
        this.sendPostRequest(output);
      } else {
        this.requestChain.push(output)
        this.$router.push({path: '/offline'}).then(() => this.resetStore());
      }
    },

    async goToNextStep() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
        return;
      }

      if (!this.signature) {
        this.snackbarMessage = {text: 'Wir Benötigen ihre Unterschrift.', type: 'warning'};
        return;
      }

      if (!this.customer.moveToUsDate) {
        this.customer.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD");
      }



      const tmpDI = this.customer.deliveryInterval;

      if (typeof tmpDI === 'string') {
        // Entfernen führender Nullen bei numerischen Werten
        if (!isNaN(Number(tmpDI))) {
          this.customer.deliveryInterval = Number(tmpDI);
        }
        // Behandeln von Bruchwerten
        else if (tmpDI.includes('/')) {
          const parts = tmpDI.split('/');
          if (parts.length === 2 && !isNaN(Number(parts[0])) && !isNaN(Number(parts[1]))) {
            const numerator = Number(parts[0]);
            const denominator = Number(parts[1]);
            if (denominator !== 0) {
              this.customer.deliveryInterval = numerator / denominator;
            } else {
              console.error('Der Nenner eines Bruchs darf nicht 0 sein.');
            }
          }
        }
      }


      const output = {
        customer: this.customer,
        additionalInformation: this.additionalInformation,
        selectedProducts: this.selectedProducts,
        selectedBox: this.selectedBox,
      };

     await this.checkOnlineAndSend(output);

    },
  },
  watch: {
    isMoveToUs() {
      if (this.isMoveToUs) {
        this.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD")
      }
    }
  }
}
</script>


<style scoped>
.privacy-link {
  cursor: pointer;
  text-decoration: underline; /* Fügt eine Unterstreichung hinzu, um klarzustellen, dass es sich um einen Link handelt. */
}

.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper-step {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;

}

.step-number-and-title {
  display: flex;
  align-items: center;
  background: #3eab36; /* Hintergrundfarbe des aktiven Schrittes */
  color: white; /* Textfarbe des aktiven Schrittes */
  padding: 5px 7px; /* Abstand um Nummer und Titel */
  border-radius: 20px; /* Abgerundete Ecken */
  margin: 0 10px;
}

.step-circle {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circlefinish {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 2px solid #3eab36;

}

.step-circleactive {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circleactive::before {
  content: '';
  position: absolute;
  top: -1px; /* Positioniert den 3/4-Kreis zentriert */
  left: -1px; /* Positioniert den 3/4-Kreis zentriert */
  width: 30px; /* Größe des 3/4-Kreises */
  height: 30px; /* Größe des 3/4-Kreises */
  border: 1px solid white; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-title {

  padding-right: 5px;/* Größe des Schritttitels */
  padding-left: 5px/* Größe des Schritttitels */
}

.dotted-line {
  flex: 1;
  height: 2px;
  margin: 0 20px; /* Erhöht den Abstand zu den Seiten */
  background-image: linear-gradient(to right, transparent, #999, transparent); /* Erstellt eine gepunktete Linie */
  background-size: 10px 2px; /* Größe der Punkte */
  background-repeat: repeat-x;
}

/* 3/4-Kreis hinzufügen */
.step-circle::before {
  content: '';
  position: absolute;
  top: -2px; /* Positioniert den 3/4-Kreis zentriert */
  left: -2px; /* Positioniert den 3/4-Kreis zentriert */
  width: 34px; /* Größe des 3/4-Kreises */
  height: 34px; /* Größe des 3/4-Kreises */
  border: 2px solid #3eab36; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
