
import {defineComponent} from 'vue'
import {mapFields} from "vuex-map-fields";
import router from "@/system/router";

export default defineComponent({
  name: "ErrorPage",
  methods: {
    router() {
      return router
    }
  },

  computed: {
    ...mapFields([
      'customer',
      'customer.collectiveAddressId',
      'customer.patientAddress',
      'customer.deliveryAddress',
      'additionalInformation',
      'additionalInformation.consultantNumber',
      'system.insurances',
      'system.selectedProducts',
      'system.selectedBox',
      'system.products',
      'system.countryCodes',
      'system.requestChain'
    ]),

    getErrorCase(){
        return this.$route.query['error'] || "NoError"
    }
  }
})
