<template>
  <v-container>
    <p-b-stepper step="2"/>
    <div class="stepper-container hidden-md-and-up">
      <!-- Schritt 1 -->
      <div class="stepper-step">
        <div class="step-circlefinish"> <v-icon color="black">mdi-check</v-icon> </div>
      </div>

      <!-- Linie zum Schritt 2 -->
      <div class="dotted-line"></div>

      <!-- Schritt 2 -->
      <div class="stepper-step active-step">
        <div class="step-number-and-title">
          <div class="step-circleactive">2</div>
          <div class="step-title">Dateneingabe</div>
        </div>
      </div>

      <!-- Linie zum Schritt 3 -->
      <div class="dotted-line"></div>

      <!-- Schritt 3 -->
      <div class="stepper-step">
        <div class="step-circle">3</div>
      </div>
    </div>

    <v-row justify="space-between" class="hidden-sm-and-down">
      <v-col cols="auto mt-4">
        <v-btn @click="goToLastStep()" text outlined x-large gtag="zurück">Zurück</v-btn>
      </v-col>
      <v-col cols="auto">
        <div class="text-right mt-4">
          <v-btn color="primary" @click="goToNextStep()" x-large gtag="weiter">Weiter</v-btn>
        </div>
      </v-col>
    </v-row>

    <h1 class="text-h4 mt-4">Daten zur pflegebedürftigen Person</h1>

    <v-form ref="form" v-model="valid" lazy-validation class="mt-4">
      <v-row>
        <v-col cols="12">
          <v-card rounded>
            <v-card-text>
              <h2 class="text-h5">Persönliche Angaben</h2>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="2" xl="2">
                  <v-autocomplete
                      v-model="customer.patientAddress.title"
                      :items="['Keine Anrede', 'Herr', 'Frau']"
                      label="Anrede"
                      :rules="rules.sirRules"
                      outlined
                  />
                </v-col>

                <v-col cols="12" xs="5" sm="6" md="6" lg="5" xl="5">
                  <v-text-field v-model="customer.patientAddress.firstName" :rules="rules.nameRules" label="Vorname"
                                outlined/>
                </v-col>

                <v-col cols="12" xs="5" sm="6" md="6" lg="5" xl="5">
                  <v-text-field v-model="customer.patientAddress.lastName" :rules="rules.nameRules" label="Nachname"
                                outlined/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card rounded>
            <v-card-text>
              <h2 class="text-h5">Ihre Kontaktdaten</h2>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="6" lg="2" xl="2">
                  <v-autocomplete
                      v-model="customer.patientAddress.countryCode"
                      :items="countryCodes"
                      item-text="name"
                      item-value="dial_code"
                      :rules="rules.prefixPhoneRules"
                      label="Ländercode"
                      outlined
                  >
                    <template v-slot:selection="data">
                      <flag :iso="data.item.code.toLowerCase()" class="mr-1"/>
                      {{ data.item.dial_code }}
                    </template>
                    <template v-slot:item="data">
                      {{ data.item.dial_code }} -
                      <flag :iso="data.item.code.toLowerCase()" class="mx-2"/>
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="6" lg="4" xl="4">
                  <v-text-field v-model="customer.patientAddress.phoneNumber" :rules="rules.phoneRules"
                                label="Telefonnummer" outlined/>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field v-model="customer.patientAddress.email" :rules="rules.emailRules" label="E-mail"
                                outlined/>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field v-model="customer.patientAddress.street" :rules="rules.streetRules"
                                label="Straße & Hausnummer" outlined/>
                </v-col>
                <v-col cols="12" xs="4" sm="4" md="6" lg="2" xl="2">
                  <v-text-field v-model="customer.patientAddress.zipCode" :rules="rules.zipRules" label="Postleitzahl"
                                outlined/>
                </v-col>
                <v-col cols="12" xs="8" sm="8" md="6" lg="4" xl="4">
                  <v-text-field v-model="customer.patientAddress.city" :rules="rules.cityRules" label="Ort" outlined/>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-checkbox
                      v-model="differentDelivery"
                      color="var(--color-tertiary-x-lighter)"
                      label="Abweichende Lieferadresse"
                  />
                  <p class="subtitle-2">
                    Die Lieferung soll nicht an die oben angegebene Adresse der pflegebedürftigen Person gesendet
                    werden? Dann
                    geben Sie hier eine abweichende Lieferanschrift an.
                  </p>

                  <v-expand-transition>
                    <div>
                      <v-row v-if="differentDelivery">
                        <v-col cols="12" v-if="consultantNumber" class="pb-0">
                          <v-autocomplete
                              :items="getCollectiveAddress"
                              v-model="collectiveAddressId"
                              item-text="name"
                              item-value="id"
                              label="Sammeladdresse"
                              clearable
                              outlined
                          />
                        </v-col>
                        <v-col class="pt-0" v-if="consultantNumber && !!collectiveAddressId">
                          <v-alert outlined color="warning">
                            die Abweichende Lieferadresse wird beim verwenden der Sammeladdresse ignoriert.
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row v-if="differentDelivery && consultantNumber">
                        <v-divider></v-divider>
                      </v-row>
                      <v-row v-if="differentDelivery">
                        <v-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                          <v-autocomplete
                              v-model="customer.deliveryAddress.title"
                              :items="['Keine Anrede', 'Herr', 'Frau']"
                              label="Anrede"
                              :rules="rules.sirRules"
                              :disabled="!!collectiveAddressId"
                              outlined
                          />
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="5" xl="5">
                          <v-text-field v-model="customer.deliveryAddress.firstName" :rules="rules.nameRules" :disabled="!!collectiveAddressId"
                                        label="Vorname" outlined/>
                        </v-col>
                        <v-col cols="12" xs="6" sm="6" md="6" lg="5" xl="5">
                          <v-text-field v-model="customer.deliveryAddress.lastName" :rules="rules.nameRules" :disabled="!!collectiveAddressId"
                                        label="Nachname" outlined/>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field v-model="customer.deliveryAddress.street" :rules="rules.streetRules" :disabled="!!collectiveAddressId"
                                        label="Straße & Hausnummer" outlined/>
                        </v-col>
                        <v-col cols="12" xs="4" sm="4" md="6" lg="6" xl="2">
                          <v-text-field v-model="customer.deliveryAddress.zipCode" :rules="rules.zipRules" :disabled="!!collectiveAddressId"
                                        label="Postleitzahl"
                                        outlined/>
                        </v-col>
                        <v-col cols="12" xs="8" sm="8" md="6" lg="6" xl="4">
                          <v-text-field v-model="customer.deliveryAddress.city" :rules="rules.cityRules" label="Ort" :disabled="!!collectiveAddressId"
                                        outlined/>
                        </v-col>

                      </v-row>
                    </div>
                  </v-expand-transition>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card rounded>
            <v-card-text>
              <h2 class="text-h5">Pflegeinformationen</h2>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="2" xl="2">
                  <p-b-datepicker :default-date="customer.birthdate" label="Geburtstag" :rules="rules.birthdateRules"
                                  :max="now" v-on:change="customer.birthdate = $event"/>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
                  <v-autocomplete
                      :items="insurances"
                      v-model="customer.insuranceId"
                      item-text="name"
                      item-value="id"
                      :rules="rules.insuranceRules"
                      label="Krankenkasse"
                      no-data-text="Wir haben keine Krankenkasse mit diesem Namen gefunden."
                      outlined/>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
                  <v-text-field v-model="customer.insuranceNumber" :rules="rules.insuranceNumberRules()"
                                label="Versichertennummer" outlined/>
                </v-col>


                <v-col v-show="showCareDegree" cols="12" xs="6" sm="6" md="6" lg="6" xl="2" v-for="(option, index) in careDegreeOptions"
                       :key="index"
                       class="hidden-md-and-down"
                >
                  <v-btn
                      :outlined="customer.careDegree !== option"
                      color="primary"
                      class="text-lg"
                      x-large
                      block
                      :class="{ 'mr-5': index < 4 }"
                      @click="setCareDegree(option)"
                      mandatory
                      rounded
                  >
                    <div class="pa-5">
                      Pflegegrad {{ option }}
                    </div>
                  </v-btn>
                </v-col>

                <v-col class="hidden-lg-and-up">
                  <v-autocomplete
                      v-model="customer.careDegree"
                      outlined
                      label="Pflegegrad"
                      :items="careDegreeOptions"
                  >
                    <template v-slot:item="{ item }">
                      Pflegegrad {{ item }}
                    </template>

                    <template v-slot:selection="{ item }">
                      Pflegegrad {{ item }}
                    </template>
                  </v-autocomplete>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card rounded>
            <v-card-text>
              <h2 class="text-h5">Ab wann möchten Sie ihre Lieferung erhalten ?</h2>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                  <v-card outlined>
                    <v-date-picker v-model="customer.firstDelivery" :allowed-dates="allowedDates" locale="de"
                                   color="primary"
                                   full-width
                    ></v-date-picker>
                  </v-card>
                </v-col>
                <v-col>
                  <v-alert outlined color="primary" type="info">
                    Bitte beachten Sie, dass es aufgrund von Bearbeitungszeiten bei der Krankenkasse zu Verzögerungen
                    kommen kann, die sich auf das Lieferdatum auswirken könnten. Wir setzen alles daran, Ihre
                    Bestellung so schnell wie möglich zu bearbeiten und zu versenden. Wir bitten um Ihr Verständnis,
                    falls es zu einer geringfügigen Verzögerung kommt.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>



        <v-col cols="12" class="fill-height" v-if="!isProductWBSelected">
          <v-card>
            <v-card-text>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-checkbox
                        label="Wiederverwendbarer Bettschutz"
                        @change="onCheckboxChange"
                        :disabled="isProductWBSelected"
                    ></v-checkbox>
                    <p class="subtitle-2">
                      Wir haben bemerkt, dass Ihr Warenkorb noch keinen wiederverwendbaren Bettschutz beinhaltet.
                      Möchten Sie
                      vielleicht einen zu Ihrer Versorgung hinzufügen?
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="space-between">
      <v-col cols="auto mt-4">
        <v-btn @click="goToLastStep()" text outlined x-large gtag="zurück">Zurück</v-btn>
      </v-col>
      <v-col cols="auto">
        <div class="text-right mt-4">
          <v-btn color="primary" @click="goToNextStep()" x-large gtag="weiter">Weiter</v-btn>
        </div>
      </v-col>
    </v-row>

    <p-b-snackbar :msg="snackbarMessage"></p-b-snackbar>
  </v-container>
</template>

<script>
import {mapMutations} from 'vuex';
import PBStepper from "@/components/PB-Stepper.vue";
import PBDatepicker from "@/components/PB-Datepicker.vue";
import moment from "moment";
import PBSnackbar from "@/components/PBSnackbar.vue";
import {mapFields} from 'vuex-map-fields';
import collectiveAddress from '@/indivconfig/collectiveAddress.json'
import releaseJson from "@/internaldata/release.json"
import store from "../system/store";
import {defineComponent} from "vue";
import Vue from "vue";

export default defineComponent({
  name: 'Step2Component',
  components: {PBSnackbar, PBDatepicker, PBStepper},
  data() {
    return {
      showSnackbar: false,
      snackbarMessage: {},
      menu: false,
      valid: true,
      now: moment().format("YYYY-MM-DD"),
      differentDelivery: false,
      careDegreeOptions: ['1', '2', '3', '4', '5'],
      showCareDegree: true,
      showInterVal: true,

      rules: {
        sirRules: [(v) => !!v || ''],
        nameRules: [(v) => !!v || 'Bitte geben Sie Ihren Namen an.'],
        prefixPhoneRules: [(v) => !!v || 'Bitte geben Sie Ihre vorwahl an.'],
        phoneRules: [(v) => !!v || 'Bitte geben Sie Ihre Telefonnummer an.'],
        emailRules: [
          (v) => !!v || 'E-Mail ist erforderlich',
          (v) => /.+@.+\..+/.test(v) || 'E-Mail muss gültig sein',
          // Hier können Sie die E-Mail-Domains hinzufügen, die Sie blockieren möchten
          (v) => !/(fake\.com|spam\.de|example\.org)$/.test(v) || 'Diese E-Mail-Domain ist nicht erlaubt'
        ],
        streetRules: [(v) => !!v || 'Bitte geben Sie Ihre Straße an.'],
        zipRules: [(v) => !!v || 'Bitte geben Sie Ihre Postleitzahl an.'],
        cityRules: [(v) => !!v || 'Bitte geben Sie Ihre Stadt an.'],
        dateRules: [v => !!v || 'Bitte geben Sie das Datum an.'],
        birthdateRules: [(v) => !!v || 'Bitte geben Sie Ihr Geburtsdatum an.'],
        insuranceRules: [(v) => !!v || 'Bitte geben Sie Ihre Krankenkasse an.'],
        insuranceNumberRules: () => {
          if (this.customer.insuranceId > 1999) {
            return [() => true];
          } else {
              return [
              v => !!v || 'Bitte geben Sie Ihre Krankenkassenummer an.',
              v => /^[A-Za-z]\d{9}$/.test(v) || 'Ungültige Krankenkassenummer.'
            ];
          }
        },
      }
    };
  },

  mounted() {
    if (!this.customer.firstDelivery) this.customer.firstDelivery = moment(moment.now()).format("YYYY-MM-DD").toString()
    if (!this.customer.deliveryInterval) this.customer.deliveryInterval = this.customer.firstDelivery?.match('(\\d{1,2})(?=\\D*$)')[0]
  },

  computed: {
    ...mapFields([
      'customer',
      'customer.collectiveAddressId',
      'additionalInformation.consultantNumber',
      'system.insurances',
      'system.selectedProducts',
      'system.products',
      'system.countryCodes',
    ]),

    getCollectiveAddress() {
      return collectiveAddress
    },

    isProductWBSelected() {
      return this.selectedProducts.some(product => product.id === 'WB');
    },

    deliveryIntervals() {
      return [
        {text: 'Monatlich', value: this.getFirstDelivery()},
        {text: 'alle 2 Monate', value: '1/6'},
        {text: 'Pro Quartal', value: '1/4'},
        {text: 'Pro Halbjahr', value: '1/2'},
      ]
    }
  },

  methods: {
    ...mapMutations(["increase"]),

    setInterval(intervall){
      this.showInterVal = false
      this.customer.deliveryInterval = intervall
      this.showInterVal = true
    },

    setCareDegree(careDegree){
      this.showCareDegree = false
      this.customer.careDegree = careDegree
      this.showCareDegree = true
    },

    getFirstDelivery(){
      const dayMatch = this.customer.firstDelivery?.match('(\\d{1,2})(?=\\D*$)')
      return dayMatch ? dayMatch[0] : ""
    },

    onCheckboxChange() {
      this.increase('WB')
      this.snackbarMessage = {
        text: 'Wir haben dreimal den wiederverwendbaren Bettschutz zu Ihrem Warenkorb hinzugefügt.',
        type: 'success'
      };
    },
    allowedDates(date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // setzt die Zeit auf Mitternacht, um nur das Datum zu vergleichen
      return new Date(date) >= currentDate;
    },
    async goToLastStep() {
      this.$router.push('/').then();
      this.$vuetify.goTo(0).then()
    },

    async goToNextStep() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
      } else {
        this.$router.push('/unterschrift').then()
        this.$vuetify.goTo(0).then()
      }
    }
  },
  created() {
    this.countryCodes.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  },
});
</script>

<style>
.stepper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper-step {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;

}

.step-number-and-title {
  display: flex;
  align-items: center;
  background: #3eab36; /* Hintergrundfarbe des aktiven Schrittes */
  color: white; /* Textfarbe des aktiven Schrittes */
  padding: 5px 7px; /* Abstand um Nummer und Titel */
  border-radius: 20px; /* Abgerundete Ecken */
  margin: 0 10px;
}

.step-circle {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circlefinish {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 2px solid #3eab36;

}

.step-circleactive {
  width: 30px; /* Größe des Kreises */
  height: 30px; /* Größe des Kreises */
  border-radius: 50%; /* Kreisform */
  line-height: 30px; /* Zentriert den Text vertikal */
  text-align: center; /* Zentriert den Text horizontal */
  display: inline-block;
  position: relative; /* Für das Positionieren des Pseudo-Elements */
}

.step-circleactive::before {
  content: '';
  position: absolute;
  top: -1px; /* Positioniert den 3/4-Kreis zentriert */
  left: -1px; /* Positioniert den 3/4-Kreis zentriert */
  width: 30px; /* Größe des 3/4-Kreises */
  height: 30px; /* Größe des 3/4-Kreises */
  border: 1px solid white; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-title {

  padding-right: 5px;/* Größe des Schritttitels */
  padding-left: 5px/* Größe des Schritttitels */
}

.dotted-line {
  flex: 1;
  height: 2px;
  margin: 0 20px; /* Erhöht den Abstand zu den Seiten */
  background-image: linear-gradient(to right, transparent, #999, transparent); /* Erstellt eine gepunktete Linie */
  background-size: 10px 2px; /* Größe der Punkte */
  background-repeat: repeat-x;
}

/* 3/4-Kreis hinzufügen */
.step-circle::before {
  content: '';
  position: absolute;
  top: -2px; /* Positioniert den 3/4-Kreis zentriert */
  left: -2px; /* Positioniert den 3/4-Kreis zentriert */
  width: 34px; /* Größe des 3/4-Kreises */
  height: 34px; /* Größe des 3/4-Kreises */
  border: 2px solid #3eab36; /* Farbe des 3/4-Kreises */
  border-radius: 50%; /* Kreisform */
  border-bottom-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
  border-left-color: transparent; /* Macht einen Viertel des Kreises unsichtbar */
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding-left: 0px;
  padding-right: 0px;
}
</style>