<template>
<span>
    <v-app-bar v-if="!showMobileHeader" flat color="white">
  <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
    <img height="60" :src="logoUrl" alt="Logo" class="py-2"/>

    <!-- Flex-Container für zentrierten Inhalt -->
      <div style="display: flex; flex-direction: column; align-items: center; flex-grow: 1; text-align: center;">
      <div class="help-text-container" v-if="!isMobile" style="max-width: 60%;"> <!-- Begrenzt die Breite des Textes für bessere Lesbarkeit -->
        <span>Benötigen Sie Hilfe bei Ihrem Onlineantrag?</span>
        <br>
        <span>Wir sind für Sie da und nehmen Ihren Antrag gerne auch telefonisch entgegen.</span>
        <br>
        <span>Unsere Telefonnummer lautet: <a :href="`tel:+4989693198120`" class="phone-number-link">+49 (0) 89 / 6931 981 20</a></span>
      </div>

      <!-- Mobiles Beratungs- und Telefonicon -->
      <div v-if="isMobile" class="advice-container" >
        <span class="advice-text">Kostenlose Beratung</span>
           <a :href="`tel:${phoneNumber}`" class="phone-link">
          <v-icon color="primary">mdi-phone</v-icon>
          <span class="phone-number" :style="{color: primaryColor}">{{ formattedPhoneNumber }}</span>
        </a>
      </div>
    </div>

    <!-- Rechte Seite mit Icons und Button -->
    <div>
      <v-icon v-if="!isOnline" color="error">mdi-wifi-remove</v-icon>
      <span class="ml-2 error--text" v-if="!isOnline">Kein Internet</span>

      <v-btn class="hidden-sm-and-down" @click="goToLink(homeSiteLink)" color="primary">
        Zurück zur Startseite
      </v-btn>
    </div>
  </div>
</v-app-bar>

<v-app-bar v-else fixed flat color="white">
    <img height="60" :src="logoUrl" alt="Logo" class="py-2"/>
    <p-b-progress style="width: 100%" class="ml-2 mr-4"/>
</v-app-bar>
</span>
</template>

<script>
import headerInfo from "@/indivconfig/headerInfo.json";
import {useOnline} from '@vueuse/core'
import PBProgress from "@/components/PB-Progress.vue";

export default {
  name: 'PB-Header',
  components: {PBProgress},
  data: () => ({
    logoUrl: headerInfo.logoUrl,
    phoneNumber: headerInfo.phoneNumber,
    homeSiteLink: headerInfo.url,
    isSticky: false
  }),

  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },

  computed: {
    isOnline() {
      return useOnline()?.value
    },

    formattedPhoneNumber() {
      // Formatieren Sie hier die Telefonnummer nach Ihren Wünschen
      return '+49 (0) 89 / 6931 981 20';
    },

    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },

    showMobileHeader() {
      if (this.isMobile) {
        return this.$store.state.system.isSticky
      }
      return false
    }
  },

}
</script>

<style scoped>

/* Stil für den Beratungshinweis und die Telefonnummer */
.advice-container {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  padding: 10px;
}

.advice-text {
  display: block;
  font-weight: bold;
}

.phone-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.phone-number {
  display: block;
  margin-left: 5px;
  font-size: 15px;
  /* Die Farbe wird durch die berechnete Eigenschaft gesteuert */
}
</style>

