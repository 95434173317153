<template>
  <v-card rounded>
    <v-card-text>
      <h2 class="text-h5">Unterschrift des Antragstellers</h2>
      <v-card-text>
        <div id="app">
          <VueSignaturePad
              class="signature-pad mt-3"
              ref="signaturePad"
              :options="{ onBegin, onEnd }"
          />
          <div class="text-right mt-2">
            <v-btn outlined color="primary" style="cursor: pointer" @click="undo">Zurücksetzen</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import {defineComponent} from 'vue'
import {mapFields} from "vuex-map-fields";

export default defineComponent({
  name: "PB-Signature",

  computed: {
    ...mapFields([
      'customer.signature',
    ]),
  },

  methods: {
    undo() {
      this.signature = undefined;
      this.$refs.signaturePad.undoSignature();
    },

    onBegin() {

    },
    onEnd() {
      this.$gtm.trackEvent({
        event: `${this.$route.path} - unterschrieben`,
        category: 'unterschrieben',
        action: 'click',
      });

      const {data} = this.$refs.signaturePad.saveSignature();
      this.signature = data;
    }
  },
})
</script>


<style scoped>

.signature-pad {
  width: 100%;
  height: 400px !important;
  border: 2px solid #a4a4a4;
  border-radius: 5px;
  background-color: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signature-pad::before {
  content: "Unterschreiben Sie hier mit Ihrem Finger oder Ihrer Maus";
  position: absolute;
  color: #a4a4a4;
}

.signature2-pad {
  width: 100%;
  height: 400px !important;
  border: 2px solid #a4a4a4;
  border-radius: 5px;
  background-color: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

</style>