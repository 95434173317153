<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default defineComponent({
  name: "PB-Progress",
  computed: {
    ...mapGetters([
      'totalValue',
    ]),

    ...mapFields([
      'additionalInformation.consultantNumber',
      'system.products',
      'system.preDesignedBoxes',
      'system.selectedProducts',
      'system.progress',
      'system.maxProgress',
      'system.minOrderValue',
    ]),
  },

  methods:{
    minOrderLinePosition() {
      return (this.minOrderValue / this.maxProgress) * 100
    }
  },


})
</script>

<template>
  <div style="position: relative;">
    <v-progress-linear
        ref="progressBar"
        :value="totalValue * 100 / maxProgress"
        :max="100"
        show-value
        striped
        color="primary"
        background-color="accent"
        height="20"
        class="rounded-xl rounded-be-0 hidden-lg-and-up"
    ></v-progress-linear>

    <!-- Schwarzer Strich bei 75% -->
    <div
        class="marker"
        :style="{ left: '75%', top: 0, bottom: 0, borderLeft: '2px solid black', position: 'absolute' }">
    </div>
  </div>
</template>


<style scoped>

</style>