
import {defineComponent} from "vue";
import PBHeader from "@/components/PB-Header.vue";
import PBFooter from "@/components/PBFooter.vue";
import releaseJson from "@/internaldata/release.json"
import {mapFields} from "vuex-map-fields";
import CookieDialog from "@/components/CookieDialog.vue";

export default defineComponent({
  components: {CookieDialog, PBFooter, PBHeader},

  mounted() {
    this.$gtm.debug(this.isLocal);
    this.$gtm.enable(localStorage.getItem('cookiesAccepted') === 'true')

    if (this.$route.query.berater) {
      this.$store.state.additionalInformation.consultantNumber = this.$route.query.berater
      console.log("consultant was set")
    }

    if (this.$route.query.selectedProvider) {
      this.$store.state.additionalInformation.selectedProvider = this.$route.query.selectedProvider;
      console.log("selectedProvider was set");
    }

    const prefill = this.$route.query.prefill;

    if (prefill) {
      const jsonString = atob(prefill.toString());
      const storeOverride = JSON.parse(jsonString)
      this.$store.commit("overrideStore", storeOverride)
    } else console.warn("prefill failed object is empty")
  },

  computed: {
    ...mapFields([""]),

    release() {
      return releaseJson
    },

    isLocal() {
      return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    }
  }
})

